import { FetchWrapper, UserDetails } from "../_models/types";

const api = `${process.env.REACT_APP_API_URL}/v1/user/`;

const getUserInformation = async (user_id: string, fetchWrapper: FetchWrapper) => fetchWrapper.get(`${api}${user_id}/`);
const updateUserDetails = async (userDetails: UserDetails, fetchWrapper: FetchWrapper) => fetchWrapper.patch(`${api}`, userDetails);
const changePassword = async (userDetails: { password: string, previous_password: string }, fetchWrapper: FetchWrapper) => fetchWrapper.patch(`${api}`, userDetails);
export const userService = {
    getUserInformation,
    updateUserDetails,
    changePassword
}
