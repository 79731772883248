import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import AlertWrapper from "../_helpers/alert-wrapper";
import useAPI from "../_hooks/useAPI";
import { ContactUs } from "../_models/types";
import { contactUsService } from "../_services";

const ContactUsPage = () => {
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
    const [successMsg, setSuccessMsg] = useState<string | null>(null)
    const [formDetails, setFormDetails] = useState<ContactUs>({ surname: "", email: "", topic: "-1", message: "", name: "" });
    const [canSubmit, setCanSubmit] = useState(false);
    const fetchWrapper = useAPI();

    useEffect(() => {
        formDetails ?
            setCanSubmit(
                !!(formDetails.message && formDetails.message.length) &&
                !!(formDetails.name && formDetails.name.length) &&
                !!(formDetails.surname && formDetails.surname.length) &&
                !!(formDetails.topic && formDetails.topic.length) &&
                !!(formDetails.email && formDetails.email.length)
            ) : setCanSubmit(false)
    }, [formDetails])


    const submitForm = async () => {
        try {
            await contactUsService.sendContactMessage(formDetails, fetchWrapper);
            setSuccessMsg("Message successfully sent");
            setFormDetails({ surname: "", email: "", topic: "-1", message: "", name: "" })
        } catch (err: any) {
            const errors = Object.keys(err);
            let errorString = "";

            if (errors.length) {
                errors.forEach(er => errorString += `${err[er]}\n`);
            } else { errorString = err };

            setErrorMsg(errorString);
        }
    }

    return <>
        <div className="col-12 faq">
            <div className="row">
                <div className="hero-image">
                    <img src="/images/L_ABOUT_us.jpg" alt="" className="w-100 img-fluid" />

                    <div className="h-100 w-100 hero-image__title">
                        <h3>
                            Tell Us About Your Experience
                        </h3>
                    </div>
                </div>
                <div className="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-4">
                    {
                        errorMsg && <AlertWrapper message={errorMsg} variant="danger" closeMsg={() => setErrorMsg(null)} />
                    }
                    {
                        successMsg && <AlertWrapper message={successMsg} variant="success" closeMsg={() => setSuccessMsg(null)} />
                    }
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="disabledSelect">Please choose a topic</Form.Label>
                            <Form.Select value={formDetails.topic} onChange={(e) => setFormDetails({ ...formDetails, topic: e.target.value } as ContactUs)}>
                                <option value="-1" disabled={true}>Select a topic</option>
                                <option value="like">I like your site</option>
                                <option value="suggestion" >Suggestions to improve this site</option>
                                <option value="complaint">I have a complaint about ReadytoWork</option>
                                <option value="question" >I have a general question about ReadytoWork</option>
                                <option value="compliment">I want to compliment ReadytoWork</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="message">
                            <Form.Label>Message</Form.Label>
                            <Form.Control as="textarea" rows={4}
                                value={formDetails.message}
                                onChange={(e) => setFormDetails({ ...formDetails, message: e.target.value } as ContactUs)} />
                        </Form.Group>

                        <div className="row">
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={formDetails.name}
                                    onChange={(e) => setFormDetails({ ...formDetails, name: e.target.value } as ContactUs)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="surname">
                                <Form.Label>Surname</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={formDetails.surname}
                                    onChange={(e) => setFormDetails({ ...formDetails, surname: e.target.value as string } as ContactUs)} />
                            </Form.Group>
                        </div>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                value={formDetails.email}
                                onChange={(e) => setFormDetails({ ...formDetails, email: e.target.value as string } as ContactUs)} />
                        </Form.Group>
                        <Button variant="primary" type="button" onClick={submitForm} disabled={!canSubmit}>
                            Send Message
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    </>
}

export default ContactUsPage;
