import { FetchWrapper } from "../_models/types";
const api = `${process.env.REACT_APP_API_URL}/v1/scorm/`;

const getInProgressCount = async (fetchWrapper: FetchWrapper) => fetchWrapper.get(`${api}get_in_progress_count/`);
const getCompletedCount = async (fetchWrapper: FetchWrapper) => fetchWrapper.get(`${api}get_completed_count/`);

export const scormService = {
    getInProgressCount,
    getCompletedCount
}
