import { FetchWrapper, LoginDetails } from "../_models/types";

const api = `${process.env.REACT_APP_API_URL}`;

const signInUser = async (loginDetails: LoginDetails, fetchWrapper: FetchWrapper) => fetchWrapper.post(`${api}/api/token/`, loginDetails);
const resetPassword = async (resetDetails: { email: string }, fetchWrapper: FetchWrapper) => fetchWrapper.post(`${api}/v1/password_reset/`, resetDetails);
export const authenticationService = {
    signInUser,
    resetPassword
}
