import { useContext, useEffect, useState } from "react";
import { Button, Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserAuthStateContext from "../_contexts/userAuth.context";
import useAPI from "../_hooks/useAPI";
import { LOGOUT, UserDetails } from "../_models/types";
import { userService } from "../_services";
import { useCommonContext } from "../_contexts/common.context";

const HeaderComponent = () => {
    const [activeLink, setActiveLink] = useState("/")
    const location = useLocation()
    const navigate = useNavigate()

    const {reloadPage} = useCommonContext()
    const { UserAuthState, dispatchUserAuth } = useContext(UserAuthStateContext);
    const [user, setUser] = useState<UserDetails | null>(null);
    const [show, setShow] = useState(false);
    const fetchWrapper = useAPI();

    useEffect(() => {
        setActiveLink(location.pathname)
    }, [location]);

    useEffect(() => {
        const getUser = async () => {
            if (UserAuthState) {
                const userDetails = await userService.getUserInformation(UserAuthState.user_id, fetchWrapper);
                setUser({ ...userDetails });
            };
        }
        getUser();
    }, [UserAuthState]);

    const toggleOffCanvas = (state: boolean) => {
        setShow(state);
    };

    const expand = "xl";
    return <>
        <Navbar key={expand} expand={expand} className="fixed-top cs-header" >
            <Container fluid>
                <div className="col-4 ps-3">
                    <Navbar.Brand className="align-content-start d-flex">
                        <Nav.Link to="/" as={Link} eventKey="home" >
                            <div className="cs-header__linkContent clientIcon">
                                <img src="/images/Absa_Logo_Smile.png" alt="Absa Logo" className="img-fluid" />
                            </div>
                        </Nav.Link>
                        <Nav.Link to="/" as={Link} eventKey="home" className="align-content-start d-flex">
                            <div className="linkContent ms-3">
                                <p className="siteName text-left">ReadytoWork <br /> e-Learning Platform</p>
                            </div>
                        </Nav.Link>
                    </Navbar.Brand>
                </div>

                <div className="col-5">
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                        show={show}
                        onHide={toggleOffCanvas}
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                ReadytoWork
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-center flex-grow-1 pe-3" fill activeKey="/">
                                <Nav.Link to="/" as={Link} eventKey="home" onClick={() => toggleOffCanvas(false)}>
                                    <div className={`linkContent ${activeLink === "/" ? "activeLink" : ""}`}>
                                        Home
                                    </div>
                                </Nav.Link>
                                <Nav.Link to="/about-us" as={Link} onClick={() => toggleOffCanvas(false)} eventKey="about-us">
                                    <div className={`linkContent ${activeLink === "/about-us" ? "activeLink" : ""}`}>
                                        About Us
                                    </div>
                                </Nav.Link>
                                <Nav.Link to="/contact-us" as={Link} onClick={() => toggleOffCanvas(false)} eventKey="contact-us">
                                    <div className={`linkContent ${activeLink === "/contact-us" ? "activeLink" : ""}`}>
                                        Contact Us
                                    </div>
                                </Nav.Link>
                                <Nav.Link to="/faq" as={Link} onClick={() => toggleOffCanvas(false)} eventKey="faq">
                                    <div className={`linkContent ${activeLink === "/faq" ? "activeLink" : ""}`}>
                                        FAQs
                                    </div>
                                </Nav.Link>
                                <Nav.Link to="/my-learning" as={Link} onClick={() => toggleOffCanvas(false)} eventKey="resources">
                                    <div className={`linkContent ${activeLink === "/my-learning" ? "activeLink" : ""}`}>
                                        {user ? 'My Learning' : 'Login'}
                                    </div>
                                </Nav.Link>
                                <Nav.Link to="/my-learning/resources" as={Link} onClick={() => toggleOffCanvas(false)} eventKey="resources">
                                    <div className={`linkContent ${activeLink === "/my-learning/resources" ? "activeLink" : ""}`}>
                                        Resources
                                    </div>
                                </Nav.Link>
                                {
                                    user && user.is_admin &&
                                    <Nav.Link eventKey="admin" onClick={() => window.open(`${process.env.REACT_APP_ADMIN_CONSOLE}`, '_blank')}>
                                        <div className="linkContent">
                                            Admin
                                        </div>
                                    </Nav.Link>
                                }
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </div>
                <div className="col-3 justify-content-end d-flex pe-3">
                    {UserAuthState || user ? 
                        <div className="px-3 d-flex align-items-center">
                            <div className="position-relative ps-4 d-lg-flex">
                                <div className="link d-flex flex-row" onClick={() => {
                                    dispatchUserAuth({ type: LOGOUT })
                                    reloadPage()
                                }}>
                                    <RiLogoutCircleRLine size="20px" className="headerIcon" /> &nbsp;&nbsp; <span className="ml-2">Logout</span>
                                </div>
                            </div> 
                        </div>
                    : 
                    <div className="px-3"><Button variant="primary" onClick={() => navigate("/login")} className="mr-2">Login</Button></div>}
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => toggleOffCanvas(true)} />
                </div>
            </Container>
        </Navbar>
    </>
}

export default HeaderComponent;
