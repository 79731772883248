import { Nav, Tab } from "react-bootstrap";
import ManageAccount from "../_components/manageAccount.component";
import ManageProfile from "../_components/manageProfile.component";

const ProfilePage = () => {
    return <div className="col-12 profile-page">
        <div className="row">
            <div className="hero-image">
                <img src="/images/J_FAQ.jpg" alt="" className="w-100 img-fluid" />
                <div className="h-100 w-100 hero-image__title">
                    <h3>
                        Manage Profile
                    </h3>
                </div>
            </div>

            <div className="col-12 col-lg-8 offset-lg-2 profile-page__body">
                <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
                    <div className="row">
                        <div className="col-12 col-md-3">
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="profile">Update Profile</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="password">Manage Account</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <div className="col-12 col-md-9">
                            <Tab.Content>
                                <Tab.Pane eventKey="profile">
                                    <ManageProfile />
                                </Tab.Pane>
                                <Tab.Pane eventKey="password">
                                    <ManageAccount />
                                </Tab.Pane>
                            </Tab.Content>
                        </div>
                    </div>
                </Tab.Container>
            </div>
        </div>
    </div>
}

export default ProfilePage;
