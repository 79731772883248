import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import AlertWrapper from "../_helpers/alert-wrapper";
import { userService } from "../_services";
import useAPI from "../_hooks/useAPI";

const ChangePassword = () => {
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
    const [successMsg, setSuccessMsg] = useState<string | null>(null)
    const fetchWrapper = useAPI();
    const [loading, setloading] = useState(false)

    const formik = useFormik({
        initialValues: {
            previous_password: "",
            password: "",
            confirm_password: ""
        },
        validationSchema: yup.object({
            previous_password: yup.string().required("Required"),
            password: yup.string().required("Required")
                .oneOf([yup.ref('confirm_password'), null], 'Passwords must match'),
            confirm_password: yup.string().required("Required")
                .oneOf([yup.ref('password'), null], 'Passwords must match'),
        }),
        onSubmit: async values => {
            setloading(true);
            setErrorMsg(null);
            setSuccessMsg(null);
            try {
                const { confirm_password, ...remaining } = values;
                await userService.changePassword(remaining, fetchWrapper);
                setSuccessMsg("Password successfully changed");
                setloading(false);
            } catch (error) {
                setErrorMsg(error as string)
                setloading(false);
            }
        }
    });

    const getError = (error: any) => {
        if (typeof error === "string") return error;
        return error.password[0];
    }
    return <div className="col-12 signin">
        <div className="row">
            <div className="hero-image">
                <img src="/images/L_ABOUT_us.jpg" alt="" className="w-100 img-fluid" />
                <div className="h-100 w-100 hero-image__title">
                    <h3>
                        Change Your Password
                    </h3>
                </div>
            </div>
            <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                {
                    errorMsg && <AlertWrapper message={getError(errorMsg)} variant="danger" closeMsg={() => setErrorMsg(null)} />
                }
                {
                    successMsg && <AlertWrapper message={successMsg} variant="success" closeMsg={() => setSuccessMsg(null)} />
                }
                <Form onSubmit={formik.handleSubmit}>
                    <Form.Group className="mb-3" controlId="currentPassword">
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Current Password"
                            name="previous_password"
                            value={formik.values.previous_password as string}
                            onChange={formik.handleChange}
                        />
                        <Form.Text className="text-danger">
                            {formik.touched.previous_password && formik.errors.previous_password ? (
                                <div className="text-danger">{formik.errors.previous_password}</div>
                            ) : null}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="newPassword">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="New Password"
                            name="password"
                            value={formik.values.password as string}
                            onChange={formik.handleChange}
                        />
                        <Form.Text className="text-danger">
                            {formik.touched.password && formik.errors.password ? (
                                <div className="text-danger">{formik.errors.password}</div>
                            ) : null}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="confirmPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Confirm Password"
                            name="confirm_password"
                            value={formik.values.confirm_password as string}
                            onChange={formik.handleChange}
                        />
                        <Form.Text className="text-danger">
                            {formik.touched.confirm_password && formik.errors.confirm_password ? (
                                <div className="text-danger">{formik.errors.confirm_password}</div>
                            ) : null}
                        </Form.Text>
                    </Form.Group>

                    <Button variant="primary" type="submit" disabled={loading}>
                        {loading && <img className="img-fluid loader-img" src="/loader.gif" alt="Loader" />} {" "} Change Password
                    </Button>
                </Form>
            </div>
        </div>
    </div>
}

export default ChangePassword;
