import Cookies from 'js-cookie';
import { decode } from 'base-64';

const amplitudeMarketingCookie = Cookies.get(`${process.env.REACT_APP_AMPLITUDE_MARKETING_COOKIE}`) as string

var utmString = ""

if (amplitudeMarketingCookie) {
	const utmData = JSON.parse(decodeURIComponent(decode(amplitudeMarketingCookie)))
    if (utmData.utm_source && utmData.utm_campaign && utmData.utm_medium) {
		  utmString = `utm_source=${utmData.utm_source}&utm_campaign=${utmData.utm_campaign}&utm_medium=${utmData.utm_medium}&utm_term=${utmData.utm_term}&utm_content=${utmData.utm_content}`
	}
}

const LandingPage = () => {
    return <div className="col-12 landing-page">
        <div className="row h-100 ">
            <div className="col-12 col-lg-9 center-items">
                <div className="row landing-page__container">
                    <div className="col-12 landing-page__platform-name">
                        <p>ReadytoWork e-Learning Platform</p>
                    </div>
                    <div className="col-12 landing-page__mobile-text py-4">
                        <p>Register on the App</p>
                    </div>
                    <div className="col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 py-3 landing-page__mobile-icons">
                        <div className="row">
                            <div
                                className="col-12 mb-4 mb-md-0 col-md-4 icon"
                                onClick={() => window.open(`https://play.google.com/store/apps/details?id=absa.readytowork&${utmString}`, '_blank')}>
                                <img src="/images/google-play.png" alt="" className="" />
                            </div>
                            <div
                                className="col-12 mb-4 mb-md-0 col-md-4 icon"
                                onClick={() => window.open('https://apps.apple.com/us/app/absa-readytowork/id1586779425', '_blank')}>
                                <img src="/images/apple-store.png" alt="" className="" />
                            </div>
                            <div
                                className="col-12 mb-4 mb-md-0 col-md-4 icon"
                                onClick={() => window.open('https://appgallery.huawei.com/app/C104833943', '_blank')}>
                                <img src="/images/appGallery.png" alt="" className="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default LandingPage;
