import { useContext, useEffect, useState } from 'react';
import { userService } from '../_services';
import useAPI from '../_hooks/useAPI';
import UserAuthStateContext from '../_contexts/userAuth.context';
import { profileService } from '../_services/profile.service';
import { UserDetails, UserProfile } from '../_models/types';
import UserDetailsForm from '../forms/userDetailsForm';

const ManageProfile = () => {
    const [user, setUser] = useState<UserDetails | null>(null);
    const [profile, setProfile] = useState<UserProfile | null>(null);
    const { UserAuthState } = useContext(UserAuthStateContext);
    const fetchWrapper = useAPI();

    useEffect(() => {
        const getUser = async () => {
            if (UserAuthState) {
                const userDetails = await userService.getUserInformation(UserAuthState.user_id, fetchWrapper);
                const userProfile = await profileService.getUserProfile(UserAuthState.user_id, fetchWrapper);
                setProfile(userProfile);
                setUser(userDetails);
            };
        }
        getUser();
    }, [UserAuthState])

    return <>
        {user && profile &&
            <UserDetailsForm user={user} />
        }
    </>
}

export default ManageProfile;
