import { useFormik } from 'formik';
import * as yup from "yup";
import { FC, useState } from "react";
import { UserDetails } from "../_models/types";
import useAPI from '../_hooks/useAPI';
import { userService } from '../_services';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import AlertWrapper from '../_helpers/alert-wrapper';
interface IProps {
    user: UserDetails
}
const UserDetailsForm: FC<IProps> = ({ user }) => {
    const [showUpdateSuccess, setShowUpdateSuccess] = useState(false)
    const fetchWrapper = useAPI();
    const formik = useFormik({
        initialValues: {
            first_name: user && user.first_name,
            last_name: user && user.last_name,
            contact_number: user && user.contact_number,
            category: user && user.category,
            town: user && user.town,
            state: user && user.state,
            country: user && user.country
        },
        validationSchema: yup.object({
            first_name: yup.string().required("Required"),
            last_name: yup.string().required("Required"),
            contact_number: yup.string().required("Required"),
            category: yup.string().required("Required"),
            town: yup.string().required("Required"),
            state: yup.string().required("Required"),
            country: yup.string().required("Required")
        }),
        onSubmit: async values => {
            try {
                setShowUpdateSuccess(false)
                await userService.updateUserDetails(values as UserDetails, fetchWrapper);
                setShowUpdateSuccess(true)
            } catch (error) {
                setShowUpdateSuccess(false)
            }
        }
    });
    return <Form onSubmit={formik.handleSubmit}>
        {
            showUpdateSuccess &&
            <AlertWrapper variant="success" message="Profile details succefully updated" closeMsg={() => setShowUpdateSuccess(false)} />
        }
        <Row className="mb-3">
            <div className="col">
                <h2>Personal Details</h2>
            </div>
            <Form.Group as={Col} sm="12" controlId="first_name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    name="first_name"
                    value={formik.values.first_name as string}
                    onChange={formik.handleChange}
                />
                <Form.Text className="text-danger">
                    {formik.touched.first_name && formik.errors.first_name ? (
                        <div className="text-danger">{formik.errors.first_name}</div>
                    ) : null}
                </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm="12" controlId="last_name">
                <Form.Label>Surname</Form.Label>
                <Form.Control
                    type="text"
                    name="last_name"
                    value={formik.values.last_name as string}
                    onChange={formik.handleChange}
                />
                <Form.Text className="text-danger">
                    {formik.touched.last_name && formik.errors.last_name ? (
                        <div className="text-danger">{formik.errors.last_name}</div>
                    ) : null}
                </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm="12" controlId="contact_number">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                    type="string"
                    name="contact_number"
                    value={formik.values.contact_number as string}
                    onChange={formik.handleChange}
                />

                <Form.Text className="text-danger">
                    {formik.touched.contact_number && formik.errors.contact_number ? (
                        <div className="text-danger">{formik.errors.contact_number}</div>
                    ) : null}
                </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm="12" controlId="category">
                <Form.Label>Category</Form.Label>
                <Form.Select
                    name="category"
                    value={formik.values.category as string}
                    onChange={formik.handleChange}
                >
                    <option>Open this select menu</option>
                    <option value="undergraduate">Undergraduate Student</option>
                    <option value="employed">Employed</option>
                    <option value="unemployed">Unemployed</option>
                </Form.Select>

                <Form.Text className="text-danger">
                    {formik.touched.category && formik.errors.category ? (
                        <div className="text-danger">{formik.errors.category}</div>
                    ) : null}
                </Form.Text>
            </Form.Group>
        </Row>
        <Row className="mb-3">
            <div className="col-12">
                <h2>Address Details</h2>
            </div>
            <Form.Group as={Col} sm="12" controlId="town">
                <Form.Label>City</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="City"
                    name="town"
                    value={formik.values.town as string}
                    onChange={formik.handleChange}
                />
                <Form.Text className="text-danger">
                    {formik.touched.town && formik.errors.town ? (
                        <div className="text-danger">{formik.errors.town}</div>
                    ) : null}
                </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm="12" controlId="state">
                <Form.Label>Province</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Province"
                    name="state"
                    value={formik.values.state as string}
                    onChange={formik.handleChange}
                />

                <Form.Text className="text-danger">
                    {formik.touched.state && formik.errors.state ? (
                        <div className="text-danger">{formik.errors.state}</div>
                    ) : null}
                </Form.Text>
            </Form.Group>

            <Form.Group as={Col} sm="12" controlId="country">
                <Form.Label>Country</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Country"
                    name="country"
                    value={formik.values.country as string}
                    onChange={formik.handleChange}
                />

                <Form.Text className="text-danger">
                    {formik.touched.country && formik.errors.country ? (
                        <div className="text-danger">{formik.errors.country}</div>
                    ) : null}
                </Form.Text>
            </Form.Group>

        </Row>
        <Button type="submit">Submit form</Button>
    </Form>
}

export default UserDetailsForm
