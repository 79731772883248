import { FC } from "react";
import { Alert, Button } from "react-bootstrap";

interface IProps {
    variant: 'primary' | 'secondary' | 'success' |
    'danger' |
    'warning' |
    'info' |
    'light' |
    'dark',
    message: string,
    closeMsg: () => void
}

const AlertWrapper: FC<IProps> = ({ variant, message, closeMsg }) => {
    return <Alert variant={variant}>
        {message}
        <div className="d-flex justify-content-end">
            <Button onClick={closeMsg} variant="outline-success">
                Close
            </Button>
        </div>
    </Alert>
}

export default AlertWrapper;
